import React, { useState, useEffect } from 'react';
import { getReaders } from '../../api/axios';
import DataTable from '../Table/DataTable';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const ReadersList = () => {
  const { t } = useTranslation('global');

  const [readers, setReaders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReaders = async() =>{
    const response = await getReaders();
    setReaders(response);
    setLoading(false);
  };
  useEffect(() => {
    fetchReaders();
  }, []);

  const columns = [
    { Header: t("list.name"), accessor: 'name' },
    { Header: t("list.serialNumber"), accessor: 'serial_num' },
    { Header: t("list.active"), accessor: 'active' }
  ];

  if (loading) {
    return <p>{t("list.loading")}</p>;
  }

  return (
    <Box sx={{ m: 10 }}>
      <DataTable data={readers} columns={columns} entityName="readers" h2={t("list.readers")} />
    </Box>
  );
};

export default ReadersList;
