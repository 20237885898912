import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const DataTable = ({ data, columns, entityName, h2 }) => {
  const { t } = useTranslation('global');

  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/${entityName}/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm(t('table.areYouSure'))) {
      // Implementacija logike za brisanje
      console.log(t('table.delete') + entityName + t('table.withId') + id);
      // Nakon brisanja osveži listu
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {h2}
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }}>
        <Table sx={{ backgroundColor: "transparent" }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.accessor} variant="head">
                  {column.Header}
                </TableCell>
              ))}
              <TableCell variant="head">{t("table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {data.map((item) => (
    <TableRow key={item.id}>
      {columns.map((column) => (
        <TableCell key={column.accessor}>
          {column.accessor === "active" 
            ? item[column.accessor] === "1" 
              ? t("table.yes") 
              : t("table.no")
            : item[column.accessor]}
        </TableCell>
      ))}
      <TableCell>
        <IconButton
          color="inherit"
          onClick={() => handleEdit(item.id)}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => handleDelete(item.id)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
