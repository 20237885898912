import Layout from './components/Layout/Layout';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PassedTickets from './components/Reports/PassedTickets';
import ActiveTickets from './components/Reports/ActiveTickets';
import NonactiveTickets from './components/Reports/NonactiveTickets';
import SoldTickets from './components/Reports/SoldTickets';
import ReportSelection from './components/ReportSelection/ReportSelection';
import TypeTickets from './components/Reports/TypeTickets';
import CategoryTickets from './components/Reports/CategoryTickets';
import KindTickets from './components/Reports/KindTickets';
import LogCardTickets from './components/Reports/LogCardTickets';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import PaymentMethod from './components/Reports/PaymentMethod';
import NewReader from './components/NewReader/NewReader';
import NewAppUser from './components/NewAppUser/NewAppUser';
import Monitoring from './components/Monitoring/Monitoring';
import DailyReport from './components/Reports/DailyReport';
import PerTicketNumber from './components/Search/PerTicketNumber';
import Unauthorized from './components/Error/Unauthorized';
import PrivatesRoutes from './components/PrivateRoute/PrivatesRoutes';
import { PERMISSIONS } from './constants/const';
import AccessRights from './components/Authorization/AccessRights';
import SuccPassedTickets from './components/Reports/SuccPassedTickets';
import ReadersList from './components/List/ReadersList';
import EditReader from './components/EDIT/EditReader';
import EditTicket from './components/EDIT/EditTicket';
import PriceList from './components/PriceList/PriceList';
import EditItem from './components/EDIT/EditItem';
import NewItem from './components/PriceList/NewItem';
import NewTicket from './components/NewTicket/NewTicket';


function App() {
    return (
            <Routes>
                <Route path="/monitor" element={<Monitoring />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route path="/" element={<Layout />}>
                    <Route index element={<ReportSelection />} />
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_HOME} />}>
                        <Route path="home" element={<Home />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_ACTIVE} />}>
                        <Route path="active" element={<ActiveTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_PASSED} />}>
                        <Route path="passed" element={<PassedTickets />} />
                        <Route path="succ_passed" element={<SuccPassedTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_NONACTIVE} />}>
                        <Route path="nonactive" element={<NonactiveTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_SOLD} />}>
                        <Route path="sold" element={<SoldTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_CATEGORY} />}>
                        <Route path="category" element={<CategoryTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_TYPE} />}>
                        <Route path="type" element={<TypeTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_KIND} />}>
                        <Route path="kind" element={<KindTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_LOG_CARD} />}>
                        <Route path="log_card" element={<LogCardTickets />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_PAYMENT_METHOD} />}>
                        <Route path="payment_method" element={<PaymentMethod />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_MONITORING} />}>
                        <Route path="monitoring" element={<Monitoring />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.CREATE_NEW_READER} />}>
                        <Route path="new-reader" element={<NewReader />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.CREATE_NEW_TICKET} />}>
                        <Route path="new-ticket" element={<NewTicket />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.CREATE_NEW_USER} />}>
                        <Route path="new-user" element={<NewAppUser />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_DAILYREP} />}>
                        <Route path="dailyrep" element={<DailyReport />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_TICKET_INFO} />}>
                        <Route path="ticket-info" element={<PerTicketNumberWrapper />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_ACCESS_RIGHTS} />}>
                        <Route path="access" element={<AccessRights />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.VIEW_LIST_READERS} />}>
                        <Route path="readers" element={<ReadersList />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.EDIT_READER} />}>
                        <Route path="readers/edit/:id" element={<EditReader />} />
                    </Route>
                    <Route element={<PrivatesRoutes permission={PERMISSIONS.EDIT_TICKET} />}>
                        <Route path="ticket/edit/:ticket_id" element={<EditTicket />} />
                    </Route>
                    {
                    /*<Route>
                        <Route path="price-list" element={<PriceList />} />
                    </Route>
                    <Route>
                        <Route path="item/edit/:item_id" element={<EditItem />} />
                    </Route>
                    <Route>
                        <Route path="new-item" element={<NewItem />} />
                    </Route>*/}
                </Route>
            </Routes>
    );
};
const PerTicketNumberWrapper = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ticket_id = searchParams.get('ticket_id');
    console.log(ticket_id);
    return <PerTicketNumber ticket_id={ticket_id} />;
};

export default App;
