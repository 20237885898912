import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";

const DailyCSV = ({ data, fileName }) => {
    const { t } = useTranslation('global');

    const convertToCSV = (reportData) => {
        let csvStr = '';

        const headers = {
            by_category: ['category', 'tickets_sold', 'total_price'],
            by_type: ['type', 'tickets_sold', 'total_price'],
            by_kind: ['kind', 'tickets_sold', 'total_price'],
            by_payment_method: ['payment_method', 'tickets_sold', 'total_price'],
            by_log_card: ['log_card', 'tickets_sold', 'total_price'],
            total: ['tickets_sold', 'total_price']
        };
        const headerMappings = {
            by_category: { category: 'Kategorija', tickets_sold: 'Broj prodatih karata', total_price: 'Ukupno' },
            by_type: { type: 'Tip', tickets_sold: 'Broj prodatih karata', total_price: 'Ukupno' },
            by_kind: { kind: 'Vrsta', tickets_sold: 'Broj prodatih karata', total_price: 'Ukupno' },
            by_payment_method: { payment_method: 'Način plaćanja', tickets_sold: 'Broj prodatih karata', total_price: 'Ukupno' },
            by_log_card: { log_card: 'Biletar', tickets_sold: 'Broj prodatih karata', total_price: 'Ukupno' },
            total: { tickets_sold: 'Ukupan broj prodatih karata', total_price: 'Ukupna cena' }
        };

        Object.keys(headers).forEach((key) => {
            csvStr += `\n${headers[key].map((header) => headerMappings[key][header] || header).join(',')}\n`;

            if (Array.isArray(reportData[key])) {
                reportData[key].forEach((item) => {
                    csvStr += `${headers[key].map((header) => item[header]).join(',')}\n`;
                });
            } else if (typeof reportData[key] === 'object') {
                csvStr += `${headers[key].map((header) => reportData[key][header]).join(',')}\n`;
            }
        });

        return csvStr;
    };

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button className='classic-button' onClick={downloadCSV} sx={{backgroundColor:"#1976d2", color:"#fff"}}>{t("CSV.exportToCSV")}</Button>
    );
}

export default DailyCSV;
