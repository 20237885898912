import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { getReaders, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';
import { useTranslation } from 'react-i18next';

const QRGenerateTicket = () => {
    const { t } = useTranslation('global');

    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hFrom, setHFrom] = useState('08:00');
    const [hTo, setHTo] = useState('19:00');
    const [dayNo, setDayNo] = useState('30');
    const [entryNo, setEntryNo] = useState('30');
    const [dailyNo, setDailyNo] = useState('0');
    const [ticketCount, setTicketCount] = useState('1');
    const [email, setEmail] = useState('');
    const [readers, setReaders] = useState([]);
    const [selectedReaders, setSelectedReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [qrCodes, setQrCodes] = useState([]);
    const [tickets, setTickets] = useState([]); // Čuva podatke o svim generisanim kartama

    useEffect(() => {
        const fetchReaders = async () => {
            try {
                const response = await getReaders();
                setReaders(response);
                setSelectedReaders(response.map(reader => reader.id));
            } catch (error) {
                console.error(t("newTicket.errorFetchingReaders"), error);
            }
        };

        fetchReaders();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let newTickets = []; // Čuva podatke za nove karte
    
        for (let i = 0; i < ticketCount; i++) {
            const newTicketId = `${Date.now()}${Math.floor(100 + Math.random() * 900)}`;
    
            const newTicket = {
                ticket_id: newTicketId,
                surname,
                name,
                phone,
                h_from: `${hFrom}:00`,
                h_to: `${hTo}:00`,
                day_no: dayNo,
                entry_no: entryNo,
                daily_no: dailyNo,
                readers: selectedReaders,
            };
    
            try {
                const response = await postRequest('/write-qr', new URLSearchParams(newTicket));
                if (!response.success) {
                    setErrMsg(t("newTicket.somethingWentWrong"));
                    return;
                }
                newTickets.push(newTicket); // Dodaj kartu u niz
            } catch (error) {
                setErrMsg(t("newTicket.failedToAddTicket"));
                return;
            }
        }
    
        setTickets(newTickets); // Ažuriraj state sa svim generisanim kartama
        console.log(newTickets);
        setSucc(t("newTicket.successfullyAddedAndSentToEmail"));
    };
    

    const handleReaderChange = (e) => {
        const value = e.target.value;
        if (value === 'all') {
            setSelectedReaders(selectedReaders.length === readers.length ? [] : readers.map(reader => reader.id));
        } else {
            setSelectedReaders((prev) =>
                prev.includes(value) ? prev.filter((id) => id !== value) : [...prev, value]
            );
        }
    };

    return (
        <Box className="container-ticket" sx={{ p: 3 }}>
            <Typography variant="h4">{t("newTicket.newPaperTicket")}</Typography>
            <form onSubmit={handleSubmit} style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.firstName")} value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.lastName")} value={surname} onChange={(e) => setSurname(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={t("newTicket.phoneNumber")} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="time" fullWidth label={t("newTicket.entryFrom")} value={hFrom} onChange={(e) => setHFrom(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="time" fullWidth label={t("newTicket.entryUntil")} value={hTo} onChange={(e) => setHTo(e.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth label={t("newTicket.numberOfDaysTheTicketIsValid")} value={dayNo} onChange={(e) => setDayNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth label={t("newTicket.totalNumberOfEntries")} value={entryNo} onChange={(e) => setEntryNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth label={t("newTicket.numberOfDailyEntries")} value={dailyNo} onChange={(e) => setDailyNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" fullWidth label={t("newTicket.ticketCount")} value={ticketCount} onChange={(e) => setTicketCount(e.target.value)} />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <TextField fullWidth label={t("newTicket.email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={() => setIsModalOpen(true)}>{t("newTicket.selectReader")}</Button>
                    </Grid>
                    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth maxWidth="sm">
    <DialogTitle>{t("newTicket.selectReader")}</DialogTitle>
    <DialogContent>
        <Grid container spacing={2} alignItems="center">
            {/* "Select All" checkbox */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedReaders.length === readers.length}
                            onChange={handleReaderChange}
                            value="all"
                        />
                    }
                    label={t("newTicket.all")}
                />
            </Grid>

            {/* Individual checkboxes */}
            {readers.map((reader) => (
                <Grid item xs={6} sm={4} key={reader.id}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedReaders.includes(reader.id)}
                                onChange={handleReaderChange}
                                value={reader.id}
                            />
                        }
                        label={reader.name}
                    />
                </Grid>
            ))}
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setIsModalOpen(false)} sx={{backgroundColor:"#1976d2", color:"white"}}>{t("newTicket.ok")}</Button>
    </DialogActions>
</Dialog>


                    <Box item xs={12} sx={{ width:"100%", display:"flex", justifyContent:"flex-end"}}>
                        <Button sx={{ width:"50%"}} variant="contained" type="submit">{t("newTicket.add")}</Button>
                    </Box>
                </Grid>
            </form>
            {tickets.length > 0 && (
    <Box mt={3}>
        <Typography variant="h6" gutterBottom>
            {t("newTicket.generatedTickets")}
        </Typography>
        {tickets.map((ticket, index) => (
            <Box 
                key={index} 
                sx={{ 
                    border: '1px solid #ccc', 
                    borderRadius: '8px', 
                    padding: 2, 
                    marginBottom: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                {/* Leva strana: QR kod */}
                <Box>
                    <QRCodeSVG value={ticket.ticket_id} style={{ margin: '10px', width: 80, height: 80 }} />
                </Box>

                {/* Desna strana: Detalji karte */}
                <Box sx={{ flex: 1, marginLeft: 2 }}>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.nameAndLastname")}</strong> {`${ticket.name} ${ticket.surname}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.phoneNumber")}</strong> {ticket.phone}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.entryTime")}</strong> {`${ticket.h_from} - ${ticket.h_to}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.numberOfDaysTheTicketIsValid")}</strong> {ticket.day_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.totalNumberOfEntries")}</strong> {ticket.entry_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.numberOfDailyEntries")}</strong> {ticket.daily_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.readers")}</strong>{" "}
                        {ticket.readers
                            .map(readerId => readers.find(reader => reader.id === readerId)?.name)
                            .join(", ")}
                    </Typography>
                </Box>
            </Box>
        ))}
    </Box>
)}
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
        </Box>
    );
};

export default QRGenerateTicket;

