import React, { useEffect, useState } from 'react';
import './notification.css';

const Notification = ({ message, onClose }) => {
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExiting(true);
      setTimeout(onClose, 500); // Matches the duration of the fadeOut animation
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <p className={`notification ${exiting ? 'notification-exit' : ''}`} style={{marginTop:"80px"}}>
      {message}
    </p>
  );
};

export default Notification;
