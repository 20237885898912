import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDailyReport } from '../../api/axios';
import './dailyReport.css';
import DailyCSV from '../CSV/DailyCSV';
import { useTranslation } from 'react-i18next';

const DailyReport = () => {
    const { t } = useTranslation('global');

    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { startDate, endDate } = location.state || {};

    useEffect(() => {
        const retreiveInfo = async () => {
            const response = await getDailyReport(startDate, endDate);
            setReport(response);
            setLoading(false);
        };

        retreiveInfo();
    }, [startDate, endDate]);

    if (loading) {
        return <div>{t("reports.loading")}</div>;
    }

    return (
        <div className="daily-report">
            <h1 className="daily-report-title">{t("reports.dailyFinancialReport")}</h1>
            <div className="daily-report-summary">
                <h2 className="daily-report-subtitle">{t("reports.SUMMARY")}</h2>
                <p>{t("reports.totalTicketsSold")}</p><p className='daily-report-result'> {report.total.tickets_sold}</p>
                <p>{t("reports.totalProfit")}</p><p className='daily-report-result'> {parseFloat(report.total.total_price || 0).toFixed(2)}</p>
            </div> {console.log(report)}
            <DailyCSV data={report} fileName={"download"} />
            <div>
                <h2 className="daily-report-subtitle">{t("reports.CATEGORY")}</h2>
                <Table data={report.by_category} groupBy="category" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">{t("reports.TYPE")}</h2>
                <Table data={report.by_type} groupBy="type" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">{t("reports.KIND")}</h2>
                <Table data={report.by_kind} groupBy="kind" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">{t("reports.PAYMENTMETHOD")}</h2>
                <Table data={report.by_payment_method} groupBy="payment_method" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">{t("reports.TICKETAGENT")}</h2>
                <Table data={report.by_log_card} groupBy="log_card" />
            </div>
        </div>
    );
}

function Table({ data, groupBy }) {
    const { t } = useTranslation('global');

    const getHeaderTitle = (groupBy) => {
        switch (groupBy) {
          case 'category':
            return t("reports.category");
          case 'type':
            return t("reports.type");
          case 'kind':
            return t("reports.kind");
          case 'payment_method':
            return t("reports.paymentMethod");
          case 'log_card':
            return t("reports.ticketAgent");
          default:
            return 'Grupa';
        }
      };

    return (
        <table className="daily-report-table">
            <thead>
                <tr>
                    <th>{getHeaderTitle(groupBy)}</th>
                    <th>{t("reports.numberOfTicketsSold")}</th>
                    <th>{t("reports.totalPrice")}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row[groupBy]}</td>
                        <td>{row.tickets_sold}</td>
                        <td>{parseFloat(row.total_price || 0).toFixed(2)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DailyReport;
