import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";

const CSV = ({ data, fileName }) => {
  const { t } = useTranslation('global');

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button className='classic-button' onClick={downloadCSV} sx={{backgroundColor:"#1976d2", color:"#fff"}}>{t("CSV.exportToCSV")}</Button>
  );
}

export default CSV;