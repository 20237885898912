import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box } from '@mui/material';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';
import { useTranslation } from 'react-i18next';
import Logout from '../Logout/Logout';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DownloadApp from '../DownloadAPP/DownloadAPP';

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const { t } = useTranslation('global');
  const [readersAnchorEl, setReadersAnchorEl] = React.useState(null);
  const [priceListAnchorEl, setPriceListAnchorEl] = React.useState(null);

  const handleReadersMenuOpen = (event) => {
    setReadersAnchorEl(event.currentTarget);
  };

  const handleReadersMenuClose = () => {
    setReadersAnchorEl(null);
  };

  const handlePriceListMenuOpen = (event) => {
    setPriceListAnchorEl(event.currentTarget);
  };

  const handlePriceListMenuClose = () => {
    setPriceListAnchorEl(null);
  };

  return (
    <>
<AppBar position="fixed" color="primary">
  <Toolbar sx={{ justifyContent: "space-between", height: "64px" }}>
    {/* Glavni Box sa dugmadima */}
    <Box sx={{ display: "flex", height: "100%" }}>
      <Button
        color="inherit"
        href="/"
        sx={{
          textTransform: "none", // Onemoguci caps lock
          fontSize: "20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            color: "#1976d2",
          },
        }}
      >
        {t("navbar.home")}
      </Button>
      <Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.CREATE_NEW_READER) && (
        <Box
        onClick={handleReadersMenuOpen}
        onMouseLeave={handleReadersMenuClose}
        sx={{ height: "100%" }}
      >
        <Button
          color="inherit"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.readers")}
        </Button>
        <Menu
          anchorEl={readersAnchorEl}
          open={Boolean(readersAnchorEl)}
          onClose={handleReadersMenuClose}
          MenuListProps={{
            onMouseLeave: handleReadersMenuClose,
          }}
          disableAutoFocusItem
        >
          <MenuItem onClick={handleReadersMenuClose} component="a" href="/new-reader">
            {t("navbar.addReader")}
          </MenuItem>
          <MenuItem onClick={handleReadersMenuClose} component="a" href="/readers">
            {t("navbar.edit/delete")}
          </MenuItem>
        </Menu>
      </Box>
      )}
<Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.CREATE_NEW_TICKET) && (
        <Button
          color="inherit"
          href="/new-ticket"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.addTicket")}
        </Button>
      )}
<Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.CREATE_NEW_USER) && (
        <Button
          color="inherit"
          href="/new-user"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.addUser")}
        </Button>
      )}
<Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.VIEW_MONITORING) && (
        <Button
          color="inherit"
          href="/monitoring"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.monitor")}
        </Button>
      )}
<Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && (
        
        <Button
          color="inherit"
          href="/access"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.rights")}
        </Button>
      )}
{/*
<Box
                  sx={{
                    width: "1px",
                    backgroundColor: "white",
                    marginY: "8px",
                    
                  }}
                />
      {hasPermission(PERMISSIONS.VIEW_PRICELIST) && (
        <Box
        onClick={handlePriceListMenuOpen}
        onMouseLeave={handlePriceListMenuClose}
        sx={{ height: "100%" }}
      >
        <Button
          color="inherit"
          sx={{
            textTransform: "none",
            fontSize: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "#1976d2",
            },
          }}
        >
          {t("navbar.priceList")}
        </Button>
        <Menu
          anchorEl={priceListAnchorEl}
          open={Boolean(priceListAnchorEl)}
          onClose={handlePriceListMenuClose}
          MenuListProps={{
            onMouseLeave: handlePriceListMenuClose,
          }}
          disableAutoFocusItem
        >
          <MenuItem onClick={handlePriceListMenuClose} component="a" href="/new-item">
            {t("navbar.addItem")}
          </MenuItem>
          <MenuItem onClick={handlePriceListMenuClose} component="a" href="/price-list">
            {t("navbar.edit/delete")}
          </MenuItem>
        </Menu>
      </Box>
      
      )}
      */}
    </Box>

    {/* Drugi Box sa ikonama */}
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DownloadApp />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LanguageSwitch />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Box>
    </Box>
  </Toolbar>
</AppBar>

{/* Spacer to prevent content overlap */}
<Toolbar />
    </>
  );
};

export default Navbar;
