import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getActiveTickets } from '../../api/axios';
import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';

const ActiveTickets = () => {
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [activeTickets, setActiveTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  // Funkcija za formatiranje datuma i vremena
  const formatDateTime = (change_time, daysOffset, time) => {
    const date = new Date(change_time);

    // Dodavanje dana za "until" kolonu
    date.setDate(date.getDate() + daysOffset);

    // Formatiramo datum i spajamo sa vremenom (h_from / h_to)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day} ${time}`;
  };

  useEffect(() => {
    const retreiveInfo = async () => {
      try {
        const response = await getActiveTickets(startDate, endDate);

        // Provera da li je response validan i ima podatke
        if (response && Array.isArray(response)) {
          const formattedTickets = response.map((ticket) => ({
            ...ticket,
            h_from: formatDateTime(ticket.change_time, 0, ticket.h_from), // Datum + vreme (h_from)
            h_to: formatDateTime(ticket.change_time, ticket.day_no - 1, ticket.h_to), // Datum uvećan + vreme (h_to)
          }));
          setActiveTickets(formattedTickets);
        } else {
          console.error('Invalid API response:', response);
          setActiveTickets([]); // Postavljamo prazan niz ako nema podataka
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
        setActiveTickets([]); // Prazan niz u slučaju greške
      } finally {
        setLoading(false);
      }
    };

    retreiveInfo();
  }, [startDate, endDate]); // Dodajemo dependencije

  const column = [
    { heading: t('reports.ticket'), value: 'ticket_id' },
    { heading: t('reports.dateCreated'), value: 'create_time' },
    { heading: t('reports.from'), value: 'h_from' }, // Datum + vreme (h_from)
    { heading: t('reports.until'), value: 'h_to' }, // Datum uvećan + vreme (h_to)
    { heading: t('reports.numberOfDays'), value: 'day_no' },
    { heading: t('reports.entryNumber'), value: 'entry_no' },
    { heading: t('reports.dailyNumberOfEntries'), value: 'daily_no' },
  ];

  return (
    <div>
      <h2>{t('reports.activeTickets')}</h2>
      {loading ? (
        <p>{t('reports.loading')}</p>
      ) : (
        <Table data={activeTickets} column={column} />
      )}
    </div>
  );
};

export default ActiveTickets;

