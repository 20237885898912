import React, { useState, useEffect } from 'react';
import './dateRangePicker.css';
import { useTranslation } from 'react-i18next';

const DateRangePicker = ({onDateRangeChange}) => {
  const { t } = useTranslation('global');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');

  // Postavljanje podrazumevanog datuma na trenutni datum prilikom prvog renderovanja komponente
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().substr(0, 10); // Formatiraj datum kao "YYYY-MM-DD"
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    // Pozovi funkciju za promenu datuma kako bi obavestio roditeljsku komponentu o podrazumevanom datumu
    onDateRangeChange(formattedDate, formattedDate, '');
  }, []); // Prazan niz znači da će se useEffect izvršiti samo jednom, prilikom prvog renderovanja

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };

  useEffect(() => {
    const today = new Date().toISOString().substr(0, 10);
    let errorMessage = '';

    if (startDate > endDate) {
      errorMessage = t("table.startDateCannotBeAfterEndDate");
    }/* else if (endDate < startDate) {
      errorMessage = t("table.endDateMustBeOnOrAfterStartDate");
    }else if (endDate > today || startDate > today) {
      errorMessage = t("table.dateMustBeOnOrBeforeToday");
    }*/

    setError(errorMessage);
    onDateRangeChange(startDate, endDate, errorMessage);
  }, [startDate, endDate, onDateRangeChange]);

  return (
    <div className="date-range-picker">
      <input 
        type="date" 
        value={startDate} 
        onChange={handleStartDateChange} 
        className="date-input"
        //max={new Date().toISOString().substr(0, 10)}
      />
      <input 
        type="date" 
        value={endDate} 
        onChange={handleEndDateChange} 
        className="date-input"
        //max={new Date().toISOString().substr(0, 10)} // Postavljamo maksimalni datum na današnji datum
      />
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default DateRangePicker;
